import * as Actions from "../actions";

const initialState = {
  loading: false,
  giverAnalytics: null,
  totalRevenue: null,
  totalVideos: null,
};

const analyticsReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ANALYTICS: {
      return {
        ...state,
        giverAnalytics: action.payload?.CompleteData,
        totalGivers: action.payload?.TotalGivers,
        totalRevenue: action.payload?.TotalRevenue,
        totalVideos: action.payload?.TotalVideos,
        loading: false,
      };
    }

    case Actions.GET_REAL_TIME_ANALYTICS: {
      return {
        ...state,
        realTimeAnalytics: action.payload,

        loading: false,
      };
    }

    case Actions.ANALYTICS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default analyticsReducer;
