import React, { useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import trolly from "../../../images/pageIcon/trolly.svg";
import baby from "../../../images/pageIcon/baby.svg";
import upperclassmenimg from "../../../images/pageIcon/upperclassmenimg.svg";
import Slider from "react-slick";
import PriceEditModal from "../modals/priceEditModal";

const Packages = ({ packages }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ],
  };

  const [modalCentered, setModalCentered] = useState(false);
  const [packageForModal, setPackageForModal] = useState(false);

  const toggleModal = (num) => {
    setModalCentered(true);
    if (num === 1) {
      setPackageForModal(packages[0]);
    }
    if (num === 2) {
      setPackageForModal(packages[1]);
    }
    if (num === 3) {
      setPackageForModal(packages[2]);
    }
  };

  return (
    <>
      <div className="container my-4">
        <Slider className="" {...settings}>
          {packages?.map((item) => (
            <div
              key={item.id}
              className="d-flex justify-content-center onHoverCard"
            >
              <div
                className={`${
                  item?.PackageNumber === 1
                    ? "sliderCardPink"
                    : item?.PackageNumber === 2
                    ? "sliderCardBlue"
                    : item?.PackageNumber === 3
                    ? "sliderCardPurple"
                    : null
                }  mx-1 p-3`}
              >
                <div className="d-flex  flex-column alilgn-items-center justify-content-center text-center">
                  <p
                    className={`${
                      item?.PackageNumber === 1
                        ? "sliderOptionPink"
                        : item?.PackageNumber === 2
                        ? "sliderOptionBlue"
                        : item?.PackageNumber === 3
                        ? "sliderOptionPurple"
                        : null
                    } m-0 mb-4`}
                  >
                    {item.option}
                  </p>
                  <div className="w-100 d-flex justify-content-center mb-3">
                    <img
                      src={
                        item?.PackageNumber === 1
                          ? trolly
                          : item?.PackageNumber === 2
                          ? baby
                          : item?.PackageNumber === 3
                          ? upperclassmenimg
                          : null
                      }
                    />
                  </div>
                  <h4 className="m-0 mb-2 dBlackColor fs-18 fw-500">
                    {item?.PackageName}
                  </h4>
                </div>
                <div className="mt-3">
                  <div className="d-flex justify-content-between">
                    <p className="m-0">Basic Price</p>
                    <p className="m-0">{item?.PackagePrice}</p>
                  </div>
                  <div className="d-flex justify-content-between">
                    <p className="m-0">DVD Request - S&H</p>
                    <p className="m-0">{item?.DVDPrice}</p>
                  </div>
                </div>
                <div className="mt-3">
                  <Button
                    onClick={() => toggleModal(item.PackageNumber)}
                    className={`${
                      item?.PackageNumber === 1
                        ? "pinkButton"
                        : item?.PackageNumber === 2
                        ? "blueButton"
                        : item?.PackageNumber === 3
                        ? "purpleButton"
                        : null
                    }  w-100`}
                  >
                    Edit Pricing
                  </Button>
                </div>
                {/*  */}
              </div>
            </div>
          ))}
        </Slider>
        <PriceEditModal
          packageForModal={packageForModal}
          modalCentered={modalCentered}
          setModalCentered={setModalCentered}
        />
      </div>
    </>
  );
};

export default Packages;
