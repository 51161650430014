import * as Actions from "../actions";

const initialState = {
  blogs: null,
  blogById: null,
  loading: false,
};

const blogReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.BLOGS_LOADING: {
      return {
        ...state,
        loading: true,
        blogById: null,
        blogs: null,
      };
    }
    case Actions.GET_BLOGS: {
      return {
        ...state,
        blogs: action.payload,
        loading: false,
      };
    }

    case Actions.GET_BLOG_BY_ID: {
      return {
        ...state,
        blogById: action.payload,
        loading: false,
      };
    }

    default: {
      return state;
    }
  }
};

export default blogReducer;
