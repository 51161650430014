import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import eye from "../../../images/pageIcon/eye.svg";
import GiverInfo from "./giverInfo";
import GiverTab from "./giverTab";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";

const GiverProfile = () => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(1);
  const { id } = useParams();

  const data = useSelector(({ giverReducer }) => giverReducer);

  const giverData = data?.giverDetail;
  const giverGallery = data?.gallery;
  const giverVideos = data?.videos;
  const giverAudios = data?.audios;
  const loading = data?.loading;

  useEffect(() => {
    let data = {
      id: id,
    };

    dispatch(actions.giversLoading(data));
    dispatch(actions.getGiverDetail(data));
  }, []);
  if (loading) {
    return (
      <div
        className="d-flex justify-content-center w-100 align-items-center"
        style={{ height: "80vh" }}
      >
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <Col lg={12}>
        <div className="mx-3 mb-4 greyColor fs-18 fw-500">
          <span>Givers</span>&nbsp;/&nbsp;
          <span className="dGreenColor fw-600">Giver Profile</span>
        </div>
        <GiverInfo giverData={giverData} />
        <GiverTab
          giverAudios={giverAudios}
          giverGallery={giverGallery}
          giverVideos={giverVideos}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
      </Col>
    </>
  );
};

export default GiverProfile;
