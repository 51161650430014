import * as domain from "./../../lib/config";
import axios from "axios";
export const GET_ANALYTICS = "[ADMIN] GET_ANALYTICS";
export const GET_ANALYTICS_ERROR = "[ADMIN] GET_ANALYTICS_ERROR";
export const ANALYTICS_LOADING = "[ADMIN] ANALYTICS_LOADING";
export const GET_REAL_TIME_ANALYTICS = "[ADMIN] GET_REAL_TIME_ANALYTICS";
export const GET_REAL_TIME_ANALYTICS_ERROR =
  "[ADMIN] GET_REAL_TIME_ANALYTICS_ERROR";

export function getAnalytics(data) {
  const request = axios.post(`${domain.default}/admin/TotalAnalytics`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_ANALYTICS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ANALYTICS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function realTimeDataAnalytics(data) {
  const request = axios.post(
    `${domain.default}/admin/getRealTimeDataAnalytics`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_REAL_TIME_ANALYTICS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_REAL_TIME_ANALYTICS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function analyticsLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: ANALYTICS_LOADING,
      payload: val,
    });
  };
}
