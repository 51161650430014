import React from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import eye from "../../../images/pageIcon/eye.svg";
import GiverHistory from "./giverHistory";
import GiverMedia from "./giverMedia";

const GiverTab = ({
  activeTab,
  setActiveTab,
  giverVideos,
  giverAudios,
  giverGallery,
}) => {
  return (
    <>
      <Card>
        <Card.Body>
          <div className="d-flex ml-4">
            <div onClick={() => setActiveTab(1)} className="mr-4">
              <p
                className={`m-0 cursorPointer ${
                  activeTab === 1 ? "dGreenColor" : "greyColor"
                }  fw-600`}
              >
                History
              </p>
              {activeTab === 1 ? (
                <hr
                  style={{ position: "absolute", width: "55px" }}
                  className="bgDGreen"
                />
              ) : null}
            </div>
            <div onClick={() => setActiveTab(2)} className="mr-4">
              <p
                className={`m-0 cursorPointer ${
                  activeTab === 2 ? "dGreenColor" : "greyColor"
                }  fw-600`}
              >
                Media Gallery
              </p>
              {activeTab === 2 ? (
                <hr
                  style={{ position: "absolute", width: "110px" }}
                  className="bgDGreen"
                />
              ) : null}
            </div>
          </div>
          <hr />
          {activeTab == 1 ? (
            <GiverHistory giverVideos={giverVideos} />
          ) : (
            <GiverMedia giverGallery={giverGallery} giverAudios={giverAudios} />
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default GiverTab;
