import * as Actions from "../actions";

const initialState = {
  getAllNotification: null,
  notificationCount: null,
};

const notificationReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_NOTIFICATION: {
      return {
        ...state,
        getAllNotification: action?.payload?.notifications,
      };
    }

    case Actions.GET_NOTIFICATION_COUNT: {
      return {
        ...state,
        notificationCount: action?.payload?.count,
      };
    }

    //

    default: {
      return state;
    }
  }
};

export default notificationReducer;
