import React, { useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import eye from "../../../images/pageIcon/eye.svg";
import ActionsModal from "../modals/actionsModal";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";

const GiverInfo = ({ giverData }) => {
  const dispatch = useDispatch();
  const [modalCentered, setModalCentered] = useState(false);

  console.log(giverData);

  const confirmCall = () => {
    let data = {
      Userid: giverData?.id,
      loginPermission: !giverData?.loginPermission,
    };
    dispatch(actions.statusChangeGiver(data));
    dispatch(actions.giversLoading(true));
    setModalCentered(!modalCentered);
  };
  return (
    <>
      <Card>
        <div className="d-flex justify-content-between align-items-center flex-wrap py-3 px-4">
          <div className="d-flex justify-content-between flex-wrap">
            <div className="mr-4">
              <p className="m-0 fs-18 fw-500 dGreenColor">
                {giverData?.username}
              </p>
              <p className="m-0">Name</p>
            </div>
            <div className="mr-4">
              <p className="m-0 fs-18 fw-500 dGreyColor">{giverData?.email}</p>
              <p className="m-0">Email</p>
            </div>
            <div className="">
              <p className="m-0 fs-18 fw-500 dGreyColor">Payments</p>
              <p className="m-0">$ {giverData?.customer_videos[0]?.Payment}</p>
            </div>
          </div>
          {giverData?.loginPermission ? (
            <div>
              <Button
                onClick={() => setModalCentered(!modalCentered)}
                className="px-5 bgRed"
              >
                Deactivate
              </Button>
            </div>
          ) : (
            <div>
              <Button
                onClick={() => setModalCentered(!modalCentered)}
                className="px-5 bgGreen"
              >
                Active
              </Button>
            </div>
          )}
        </div>
        <ActionsModal
          modalCentered={modalCentered}
          setModalCentered={setModalCentered}
          action="deactivate"
          confirmCall={confirmCall}
        />
      </Card>
    </>
  );
};

export default GiverInfo;
