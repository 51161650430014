import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button, FormGroup } from "react-bootstrap";
import blogImg from "../../../../images/pageIcon/blogImg.png";
import { Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../../store/actions/index";
import axios from "axios";
import domain from "../../../../lib/config";
import { useParams } from "react-router";
import fileUpload from "../../../../images/fileUpload.svg";
const instance = new axios.create({});
delete instance.defaults.headers.common[`Authorization`];

const AddBlog = () => {
  const dispatch = useDispatch();
  const { id, name } = useParams();

  const [file, setFile] = useState({
    blogImgLoading: false,
    blogVideo1Loading: false,
    blogVideo2Loading: false,
    blogVideo3Loading: false,
  });

  const blogById = useSelector(
    ({ blogReducer }) => blogReducer?.blogById?.Blog
  );

  console.log(file);

  useEffect(() => {
    if (blogById) {
      setFile({
        ...file,
        blogVideo1: blogById?.BlogVideo1,
        blogImg: blogById?.BlogImage,
        blogVideo2: blogById?.BlogVideo2,
        blogVideo3: blogById?.BlogVideo3,
      });
    }
  }, [blogById]);

  useEffect(() => {
    let data = {
      id: parseInt(id),
    };
    dispatch(actions.blogLoading(data));
    dispatch(actions.getBlogById(data));
  }, []);

  // file upload s3
  async function handleChange(event) {
    console.log(event);
    const loading = `${event.target.name}Loading`;
    setFile({ ...file, [loading]: true });
    const data = new FormData();
    data.append("UserFile", event.target.files[0]);
    axios
      .post(`${domain}/uservideos/uploadfiletosebucket`, data)
      .then((response) => {
        setFile({
          ...file,
          [event.target.name]: response?.data?.result?.Location,
          [loading]: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }
  const onSubmit = (e) => {
    e.preventDefault();
    let data = {
      BlogTitle: e.target.title.value,
      BlogDescription: e.target.blogdescription.value,
      BlogImage: file?.blogImg,
      BlogBody: e.target.blogbody.value,
      BlogVideo1: file?.blogVideo1,
      BlogNumber:
        name === "form1"
          ? 1
          : name === "form2"
          ? 2
          : name === "form3"
          ? 3
          : null,
    };

    let data2 = {
      BlogVideo2: file?.blogVideo2,
      BlogVideo3: file?.blogVideo3,
    };

    let data3 = {
      BlogBody2: e.target.blogbody2.value,
      BlogBody3: e.target.blogbody3.value,
    };

    // this is for form 1 dispatch
    if (name === "form1") {
      if (id) {
        data.id = parseInt(id);
        dispatch(actions.updateBlog(data));
      } else {
        dispatch(actions.addBlog(data));
      }
    }

    // this is for form 2 dispatch
    if (name === "form2") {
      let merged = { ...data, ...data2 };
      console.log(merged);
      if (id) {
        merged.id = parseInt(id);
        dispatch(actions.updateBlog(merged));
      } else {
        dispatch(actions.addBlog(merged));
      }
    }

    if (name === "form3") {
      let merged = { ...data, ...data2, ...data3 };
      console.log(merged);
      if (id) {
        merged.id = parseInt(id);
        dispatch(actions.updateBlog(merged));
      } else {
        dispatch(actions.addBlog(merged));
      }
    }
  };

  return (
    <>
      <Col lg={12}>
        <Card>
          <Card.Body>
            <div>
              <form onSubmit={onSubmit}>
                <div className="d-flex w-100">
                  <div className="w-100 m-2">
                    <FormGroup>
                      <Label for="exampleEmail">Blog title</Label>
                      <Input
                        defaultValue={blogById?.BlogTitle}
                        type="text"
                        name="title"
                        id="exampleEmail"
                        placeholder="Blog title"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="w-100 m-2">
                  <label>Blog Image</label>
                  <div
                    style={{ width: "200px" }}
                    className="imageUpload d-flex justify-content-center align-items-center"
                  >
                    <label for="blogImg">
                      {file?.blogImgLoading ? (
                        <div class="spinner-border" role="status">
                          <span class="sr-only"></span>
                        </div>
                      ) : (
                        <img
                          className="w-100 object-fit-cover cursor-pointer"
                          src={file?.blogImg ? file?.blogImg : fileUpload}
                        />
                      )}
                    </label>
                    <Input
                      id="blogImg"
                      name="blogImg"
                      onChange={handleChange}
                      type="file"
                    />
                  </div>
                </div>

                <div className="d-flex w-100">
                  <div className="w-100 m-2">
                    <FormGroup>
                      <Label for="exampleEmail">Blog description</Label>
                      <Input
                        defaultValue={blogById?.BlogDescription}
                        type="textarea"
                        name="blogdescription"
                        id="exampleEmail"
                        placeholder="description"
                      />
                    </FormGroup>
                  </div>
                </div>
                {/*  */}

                {name === "form1" || name === "form2" ? (
                  <>
                    <div className="d-flex w-100">
                      <div className="w-100 m-2">
                        <FormGroup>
                          <Label for="exampleEmail">Blog Body</Label>
                          <Input
                            defaultValue={blogById?.BlogBody}
                            type="textarea"
                            name="blogbody"
                            id="exampleEmail"
                            placeholder="body"
                          />
                        </FormGroup>
                      </div>
                    </div>
                    <div className="d-flex align-items-center w-100">
                      {/* blog video 1..........
                  ...............................
                  ............................. */}
                      <div className="w-100 m-2">
                        <label>Blog Video</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo1">
                            {file?.blogVideo1Loading ? (
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            ) : (
                              <img
                                className="w-100 object-fit-cover cursor-pointer"
                                src={
                                  file?.blogVideo1
                                    ? file?.blogVideo1
                                    : fileUpload
                                }
                              />
                            )}
                          </label>
                          <Input
                            id="blogVideo1"
                            name="blogVideo1"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      {/* blog video 2..........
                  ...............................
                  ............................. */}
                      {name === "form2" ? (
                        <>
                          <div className="w-100 m-2">
                            <label>Blog Video 2</label>
                            <div
                              style={{ width: "200px" }}
                              className="imageUpload d-flex justify-content-center align-items-center"
                            >
                              <label for="blogVideo2">
                                {file?.blogVideo2Loading ? (
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                ) : (
                                  <img
                                    className="w-100 object-fit-cover cursor-pointer"
                                    src={
                                      file?.blogVideo2
                                        ? file?.blogVideo2
                                        : fileUpload
                                    }
                                  />
                                )}
                              </label>
                              <Input
                                id="blogVideo2"
                                name="blogVideo2"
                                onChange={handleChange}
                                type="file"
                              />
                            </div>
                          </div>

                          {/* blog video 3..........
                  ...............................
                  ............................. */}

                          <div className="w-100 m-2">
                            <label>Blog Video 3</label>
                            <div
                              style={{ width: "200px" }}
                              className="imageUpload d-flex justify-content-center align-items-center"
                            >
                              <label for="blogVideo3">
                                {file?.blogVideo3Loading ? (
                                  <div class="spinner-border" role="status">
                                    <span class="sr-only"></span>
                                  </div>
                                ) : (
                                  <img
                                    className="w-100 object-fit-cover cursor-pointer"
                                    src={
                                      file?.blogVideo3
                                        ? file?.blogVideo3
                                        : fileUpload
                                    }
                                  />
                                )}
                              </label>
                              <Input
                                id="blogVideo3"
                                name="blogVideo3"
                                onChange={handleChange}
                                type="file"
                              />
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="d-flex justify-content-end w-100">
                        <div>
                          <Button type="submit" className="">
                            Submit
                          </Button>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="d-flex justify-content-between">
                    {/* blog video 1 form 3 */}
                    <div>
                      <div className="w-100 m-2">
                        <label>Blog Video</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo1">
                            {file?.blogVideo1Loading ? (
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            ) : (
                              <img
                                className="w-100 object-fit-cover cursor-pointer"
                                src={
                                  file?.blogVideo1
                                    ? file?.blogVideo1
                                    : fileUpload
                                }
                              />
                            )}
                          </label>
                          <Input
                            id="blogVideo1"
                            name="blogVideo1"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <div className="w-100 m-2">
                          <FormGroup>
                            <Label for="exampleEmail">
                              Blog video description
                            </Label>
                            <Input
                              defaultValue={blogById?.BlogBody}
                              type="textarea"
                              name="blogbody"
                              id="exampleEmail"
                              placeholder="description"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    {/* blog video 2 form 3 */}
                    <div>
                      <div className="w-100 m-2">
                        <label>Blog Video 2</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo2">
                            {file?.blogVideo2Loading ? (
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            ) : (
                              <img
                                className="w-100 object-fit-cover cursor-pointer"
                                src={
                                  file?.blogVideo2
                                    ? file?.blogVideo2
                                    : fileUpload
                                }
                              />
                            )}
                          </label>
                          <Input
                            id="blogVideo2"
                            name="blogVideo2"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <div className="w-100 m-2">
                          <FormGroup>
                            <Label for="exampleEmail">
                              Blog video 2 description
                            </Label>
                            <Input
                              defaultValue={blogById?.BlogBody2}
                              type="textarea"
                              name="blogbody2"
                              id="exampleEmail"
                              placeholder="description"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>

                    {/* blog video 3 form 3 */}
                    <div>
                      <div className="w-100 m-2">
                        <label>Blog Video 3</label>
                        <div
                          style={{ width: "200px" }}
                          className="imageUpload d-flex justify-content-center align-items-center"
                        >
                          <label for="blogVideo3">
                            {file?.blogVideo3Loading ? (
                              <div class="spinner-border" role="status">
                                <span class="sr-only"></span>
                              </div>
                            ) : (
                              <img
                                className="w-100 object-fit-cover cursor-pointer"
                                src={
                                  file?.blogVideo3
                                    ? file?.blogVideo3
                                    : fileUpload
                                }
                              />
                            )}
                          </label>
                          <Input
                            id="blogVideo3"
                            name="blogVideo3"
                            onChange={handleChange}
                            type="file"
                          />
                        </div>
                      </div>
                      <div className="d-flex w-100">
                        <div className="w-100 m-2">
                          <FormGroup>
                            <Label for="exampleEmail">
                              Blog video 3 description
                            </Label>
                            <Input
                              defaultValue={blogById?.BlogBody3}
                              type="textarea"
                              name="blogbody3"
                              id="exampleEmail"
                              placeholder="description"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-end align-items-end">
                      <div>
                        <Button type="submit" className="">
                          Submit
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </form>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default AddBlog;
