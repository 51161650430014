import React, { useState } from "react";
/// React router dom
import { Switch, Route } from "react-router-dom";
/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
/// Dashboard
import Home from "./components/Dashboard/Home";

//Scroll To Top
import ScrollToTop from "./layouts/ScrollToTop";
import Givers from "./components/Givers";
import GiverProfile from "./components/giverProfile";
import ManagePackages from "./components/managePackages";
import BlogsManager from "./components/blogsManager";
import About from "./components/about";
import Videos from "./components/videos";
import GiverVideoMedia from "./components/giverProfile/giverVideoMedia";
import AddBlog from "./components/blogsManager/addBlog";

const Markup = () => {
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];
  let pagePath = path.split("-").includes("page");
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /// Dashboard
    { url: "", component: Home },
    { url: "analytics", component: Home },
    // Givers
    { url: "givers", component: Givers },
    { url: "giver/:id", component: GiverProfile },
    // Manage Packages
    { url: "packages", component: ManagePackages },
    //Blogs Manager
    { url: "blogs", component: BlogsManager },
    // AddBlog
    { url: "addblog/:name", component: AddBlog },
    // EditBlog
    { url: "editblog/:name/:id", component: AddBlog },
    //About
    { url: "about", component: About },
    // Videos
    { url: "videos", component: Videos },
    // giver video media
    { url: "giver/videomedia/:id", component: GiverVideoMedia },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? "rightside-event" : ""} ${
            !pagePath ? "content-body" : ""
          }`}
        >
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
