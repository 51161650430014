import * as domain from "./../../lib/config";
import axios from "axios";
export const GET_ALL_GIVERS = "[ADMIN] GET_ALL_GIVERS";
export const GET_ALL_GIVERS_ERROR = "[ADMIN] GET_ALL_GIVERS_ERROR";
export const GIVERS_LOADING = "[ADMIN] GIVERS_LOADING";
export const GET_GIVER_DETAIL = "[ADMIN] GET_GIVERS_DETAIL";
export const GET_GIVERS_DETAIL_ERROR = "[ADMIN] GET_GIVERS_DETAIL_ERROR";
export const GET_VIDEO_MEDIA_ERROR = "[ADMIN] GET_VIDEO_MEDIA_ERROR";
export const GET_VIDEO_MEDIA = "[ADMIN] GET_VIDEO_MEDIA";

export function getAllGivers(data) {
  console.log(domain.default);
  const request = axios.post(`${domain.default}/admin/getAllGivers`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_ALL_GIVERS,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_ALL_GIVERS_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function getGiverDetail(data) {
  console.log(domain.default);
  const request = axios.post(`${domain.default}/admin/GiverDetails`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_GIVER_DETAIL,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_GIVERS_DETAIL_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function getVideoMedia(data) {
  console.log(domain.default);
  const request = axios.post(`${domain.default}/admin/VideoDetails`, data);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_VIDEO_MEDIA,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_VIDEO_MEDIA_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function statusChangeGiver(data) {
  console.log(domain.default);
  const request = axios.post(`${domain.default}/admin/DeactivateUser`, data);
  return (dispatch) =>
    request
      .then((response) => {
        dispatch(
          getGiverDetail({
            id: data?.Userid,
          })
        );
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
}

export function giversLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: GIVERS_LOADING,
      payload: val,
    });
  };
}
