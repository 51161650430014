import * as Actions from "../actions";

const initialState = {
  allGivers: null,
  loading: false,
  count: null,
  giverDetail: null,
  videos: null,
  gallery: null,
  audios: null,
  videoMedia: null,
};

const giverReducer = function (state = initialState, action) {
  switch (action.type) {
    case Actions.GET_ALL_GIVERS: {
      return {
        ...state,
        allGivers: action.payload?.CompleteData,
        count: action.payload?.TotalCount,
        loading: false,
      };
    }

    case Actions.GET_GIVER_DETAIL: {
      return {
        ...state,
        giverDetail: action.payload?.GiverInfo,
        gallery: action.payload?.Gallay,
        videos: action.payload?.userVideos,
        audios: action.payload?.AudioLink,
        loading: false,
      };
    }

    case Actions.GET_VIDEO_MEDIA: {
      return {
        ...state,
        videoMedia: action.payload,
        loading: false,
      };
    }

    case Actions.GIVERS_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default giverReducer;
