import React, { useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Modal } from "react-bootstrap";
import cross from "../../../images/pageIcon/cross.svg";
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap";
import axios from "axios";
import domain from "../../../lib/config";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";

const VideoAddModal = ({ modalCentered, setModalCentered, categories }) => {
  const dispatch = useDispatch();

  const [inputData, setInputData] = useState({
    LearningCategoryId: null,
    LearningVideoName: null,
    LinkUrl: null,
    LinkUrlLocal: null,
    LinkUrlLoading: false,
  });

  const onChange = (e) => {
    setInputData({ ...inputData, [e.target.name]: e.target.value });
  };

  // file upload
  async function handleChange(event) {
    const loading = `${event.target.name}Loading`;
    setInputData({ ...inputData, [loading]: true });
    const data = new FormData();
    data.append("UserFile", event.target.files[0]);
    let fileLink;
    axios
      .post(`${domain}/uservideo/uploadLearningData`, data)
      .then((response) => {
        console.log(response);
        fileLink = response?.data?.FileData?.tempFileUrl;
      })
      .catch((error) => {
        console.log(error.response);
      });

    axios
      .post(`${domain}/uservideos/uploadfiletosebucket`, data)
      .then((response) => {
        console.log(response);
        setInputData({
          ...inputData,
          [event.target.name]: response?.data?.result?.Location,
          [`${event.target.name}Local`]: fileLink,
          [loading]: false,
        });
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  const onSubmitAddVideo = (e) => {
    e.preventDefault();
    let data = {
      LearningCategoryId: inputData?.LearningCategoryId,
      LearningVideoName: inputData?.LearningVideoName,
      LinkUrl: inputData?.LinkUrl,
      TempFileLink: inputData?.LinkUrlLocal,
    };
    if (data?.LinkUrl) {
      dispatch(actions.addVideos(data));
    }
    setModalCentered(!modalCentered);
    console.log(data);
  };

  return (
    <>
      {/* <!-- Button trigger modal --> */}
      {/* <!-- Modal --> */}
      <Modal className="fade" centered show={modalCentered}>
        <div className="mb-3 py-3 px-4">
          <div
            onClick={() => setModalCentered(!modalCentered)}
            className="d-flex justify-content-end cursorPointer"
          >
            <img src={cross} />
          </div>
          <div className=" my-4">
            <Form onSubmit={onSubmitAddVideo}>
              <FormGroup>
                <Label for="exampleSelect">Select Category</Label>
                <Input
                  onChange={onChange}
                  required
                  type="select"
                  name="LearningCategoryId"
                  id="exampleSelect"
                >
                  <option value="">Select Categories</option>
                  {categories?.map((item) => (
                    <option value={item.id} key={item?.id}>
                      {item?.LearningVideoCategoryName}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                <Label for="exampleEmail">Video Name</Label>
                <Input
                  type="text"
                  required
                  onChange={onChange}
                  name="LearningVideoName"
                  id="exampleEmail"
                  placeholder="with a placeholder"
                />
              </FormGroup>
              <FormGroup>
                <Label for="exampleFile">File</Label>
                <Input
                  onChange={handleChange}
                  type="file"
                  name="LinkUrl"
                  id="exampleFile"
                />
                {inputData?.LinkUrlLoading && (
                  <div class="spinner-border" role="status">
                    <span class="sr-only"></span>
                  </div>
                )}
                <FormText color="muted">
                  This is some placeholder block-level help text for the above
                  input. It's a bit lighter and easily wraps to a new line.
                </FormText>
              </FormGroup>
              <div className="d-flex justify-content-end">
                <div className="">
                  <Button type="submit" className={`bgDGreen py-2 ml-2`}>
                    Save
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default VideoAddModal;
