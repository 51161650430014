import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import pMinDelay from "p-min-delay";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row, Col, Card } from "react-bootstrap";
import GiverTable from "./giverTable";
import TotalCount from "./totalCount";
import revenue from "../../../images/pageIcon/revenueGenerate.svg";
import calender from "../../../images/pageIcon/calender.svg";
import { Dropdown, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../../store/actions/index";
const ApexLine3 = loadable(() => pMinDelay(import("./lineChart"), 500));

const Home = () => {
  const dispatch = useDispatch();
  const [dropDown, setDropDown] = useState("monthly");

  const data = useSelector(({ analyticsReducer }) => analyticsReducer);
  const analyticsGiver = data?.giverAnalytics;
  const totalRevenue = data?.totalRevenue;
  const totalVideos = data?.totalVideos;
  const totalGivers = data?.totalGivers;
  const realTimeAnalytics = data?.realTimeAnalytics?.data;

  useEffect(() => {
    dispatch(actions.analyticsLoading());
    dispatch(actions.getAnalytics());
    let data = {
      TimeSeries: dropDown,
    };
    dispatch(actions.realTimeDataAnalytics(data));
  }, [dropDown]);

  const dropDownAnalytics = [
    {
      id: 1,
      value: "today",
    },
    {
      id: 2,
      value: "weekly",
    },
    {
      id: 3,
      value: "monthly",
    },
  ];

  return (
    <>
      <div className="row">
        <Col xl={8}>
          <Card>
            <div className="py-4 px-4 px-md-5">
              <div className="d-flex justify-content-between align-items-center flex-wrap">
                <div className="dBlackColor fs-20 fw-500 text-nowrap">
                  <img src={revenue} /> &nbsp;Revenue Generated
                </div>
                <div className="d-flex align-items-center mt-3 mt-sm-0">
                  <div className="mr-3">
                    <img src={calender} />
                  </div>
                  <div>
                    <Dropdown className="dropdown">
                      <Dropdown.Toggle
                        as="button"
                        variant=""
                        className="btn rounded border border-light dropdown-toggle"
                      >
                        {dropDown}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
                        {dropDownAnalytics?.map((item) => (
                          <Dropdown.Item
                            key={item.id}
                            onClick={() => setDropDown(item?.value)}
                          >
                            {item.value}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
            </div>
            <Card.Body>
              <ApexLine3 realTimeAnalytics={realTimeAnalytics} />
            </Card.Body>
          </Card>
        </Col>
        <TotalCount
          totalRevenue={totalRevenue}
          totalVideos={totalVideos}
          totalGivers={totalGivers}
        />
        <GiverTable analyticsGiver={analyticsGiver} />
      </div>
    </>
  );
};

export default Home;
