import * as domain from "./../../lib/config";
import axios from "axios";
export const GET_PACKAGES_DETAIL = "[ADMIN] GET_PACKAGES_DETAIL";
export const GET_PACKAGES_DETAIL_ERROR = "[ADMIN] GET_PACKAGES_DETAIL_ERROR";
export const GET_PACKAGES_LOADING = "[ADMIN] GET_PACKAGES_LOADING";

export function getPackagesDetail(data) {
  console.log(domain.default);
  const request = axios.get(`${domain.default}/packages/getAllPackages`);
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);
        return dispatch({
          type: GET_PACKAGES_DETAIL,
          payload: response.data,
        });
      })
      .catch((error) => {
        return dispatch({
          type: GET_PACKAGES_DETAIL_ERROR,
          payload:
            error.response && error.response.data.msg
              ? error.response.data.msg
              : "Error! Cannot get brands",
        });
      });
}

export function updatePackage(data) {
  console.log(domain.default);
  const request = axios.put(
    `${domain.default}/package/updatePackagePrice`,
    data
  );
  return (dispatch) =>
    request
      .then((response) => {
        console.log(response);

        return dispatch(getPackagesDetail());
      })
      .catch((error) => {
        console.log(error);
        alert("rejected");
      });
}

export function packagesLoading(val) {
  return (dispatch) => {
    return dispatch({
      type: GET_PACKAGES_LOADING,
      payload: val,
    });
  };
}
