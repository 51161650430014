import React from "react";
import { Col, Card, Table, Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import eye from "../../../images/pageIcon/eye.svg";
import darkEye from "../../../images/pageIcon/darkEye.svg";

const GiverTable = ({ analyticsGiver }) => {
  const hData = [
    {
      id: 1,
      th: "#",
    },
    {
      id: 2,
      th: "Name",
    },
    {
      id: 3,
      th: "Email",
    },
    {
      id: 4,
      th: "Payments",
    },
    {
      id: 5,
      th: "Video Created",
    },
  ];

  return (
    <>
      <Col lg={12}>
        <Card>
          <Card.Header>
            <Card.Title>Giver Analytics</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive className="header-border ">
              <thead>
                <tr>
                  {hData.map((item) => (
                    <th
                      key={item.id}
                      className="greyColor fs-14 fw-600"
                      key={item.id}
                    >
                      {item.th}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {analyticsGiver?.map((item) => (
                  <tr key={item.id}>
                    <td className="greyColor fs-16 fw-500">
                      <Link href="#">{item.id}</Link>
                    </td>
                    <td className="greyColor fs-16 fw-500">{item.username}</td>
                    <td className="greyColor fs-16 fw-500">{item.email}</td>
                    <td className="greyColor fs-16 fw-500">
                      $ &nbsp;
                      {item?.Payment.toFixed(2)}
                    </td>
                    <td className="greyColor fs-16 fw-500">
                      {item?.TotalVideos}
                      {/* <Badge variant="success"></Badge> */}
                    </td>
                    <td className="greyColor fs-16 fw-500">
                      <Link to={`/giver/${item?.id}`}>
                        {item?.TotalVideos ? (
                          <img src={darkEye} />
                        ) : (
                          <img src={eye} />
                        )}
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
      </Col>
    </>
  );
};

export default GiverTable;
