import React from "react";
import { Link } from "react-router-dom";
import loadable from "@loadable/component";
import { Col, Card, Table, Badge, Button } from "react-bootstrap";
import eye from "../../../images/pageIcon/eye.svg";

const About = () => {
  return (
    <>
      <Col lg={12}>
        <Card>
          <div className="p-4">
            <div className="mb-5">
              <p className="fw-500 dGreenColor fs-24">How it works?</p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor
                sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua.
              </p>
            </div>
            <div className="mb-5">
              <p className="fw-500 dGreenColor fs-24">How it works?</p>
              <div className="d-flex mb-4 align-item-center">
                <img src="#" />
                <p className="m-0 ml-4">www.example.com</p>
              </div>
              <div className="d-flex mb-4 align-item-center">
                <img src="#" />
                <p className="m-0 ml-4">+92 333 1234567</p>
              </div>
              <div className="d-flex align-item-center">
                <img src="#" />
                <p className="m-0 ml-4">Islamabad, Pakistan</p>
              </div>
            </div>
            <div className="mb-5">
              <p className="fw-500 dGreenColor fs-24">Follow Us</p>
              <div className="d-flex mb-4 align-item-center">
                <img src="#" />
                <p className="m-0 ml-4">Whatsapp</p>
              </div>
              <div className="d-flex mb-4 align-item-center">
                <img src="#" />
                <p className="m-0 ml-4">Instagram</p>
              </div>
              <div className="d-flex align-item-center">
                <img src="#" />
                <p className="m-0 ml-4">Facebook</p>
              </div>
            </div>
            <div className="">
              <p className="fw-500 dGreenColor fs-24">Developed By</p>
              <p>Infinity Bits</p>
            </div>
          </div>
        </Card>
      </Col>
    </>
  );
};

export default About;
